import { ApolloClient, ApolloLink, from, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";

// const httpLink = new HttpLink({
//   uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
// });

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_SHOP_URL,
});

const middlewareAuthLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("token") || null;
  const vendureToken = localStorage.getItem("auth-token");
  const vendureTokenSegment = vendureToken
    ? { "auth-token": vendureToken }
    : {};

  const authorizationHeader = token ? `Bearer ${token}` : null;
  operation.setContext({
    headers: {
      authorization: authorizationHeader,
      ...vendureTokenSegment,
    },
  });
  return forward(operation);
});

const afterLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    if (headers) {
      const refreshToken = headers.get("vendure-auth-token");
      if (refreshToken) {
        localStorage.setItem("token", refreshToken);
      }
    }

    if (
      response?.errors?.find(
        (k) =>
          k.message ===
          "You are not currently authorized to perform this action"
      )
    ) {
      window.location = "/login";
      localStorage.clear();
    }

    return response;
  });
});

const client = new ApolloClient({
  link: from([middlewareAuthLink, afterLink, httpLink]),
  cache: new InMemoryCache(),
});

export default client;
