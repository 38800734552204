import axios from "axios";

export const getAuthToken = async () => {
  if (
    new Date() - localStorage.getItem("shiprocket-refresh") <
    5 * 24 * 60 * 60 * 1000
  ) {
    return;
  }

  const config = {
    method: "POST",
    url: "/v1/external/auth/login",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      email: process.env.REACT_APP_SHIPROCKET_USER,
      password: process.env.REACT_APP_SHIPROCKET_PASSWORD,
    },
  };

  const { data } = await axios(config);
  localStorage.setItem("shiprocket-token", data.token);
  localStorage.setItem("shiprocket-refresh", Date.now());
};

export const getPickupLocations = () => {
  const config = {
    method: "get",
    url: "/v1/external/settings/company/pickup",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("shiprocket-token")}`,
    },
  };

  return axios(config);
};

export const getCouriers = (pickupPostcode, deliveryPostcode, weight) => {
  const config = {
    method: "get",
    url: "/v1/external/courier/serviceability/",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("shiprocket-token")}`,
    },
    params: {
      pickup_postcode: pickupPostcode,
      delivery_postcode: deliveryPostcode,
      cod: 0,
      weight,
    },
  };

  return axios(config);
};

export const getLabel = (shipmentId) => {
  const config = {
    method: "post",
    url: "/v1/external/courier/generate/label",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("shiprocket-token")}`,
    },
    data: JSON.stringify({
      shipment_id: [shipmentId],
    }),
  };

  return axios(config);
};

export const getShiprocketOrders = (channel_order_id) => {
  const config = {
    method: "get",
    url: `/v1/external/orders?filter=${channel_order_id}&filter_by=channel_order_id`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("shiprocket-token")}`,
    },
  };

  return axios(config);
};
